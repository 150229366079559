<!-- Check if customer is available and if data is still loading -->
<div class="container" *ngIf="!customer && auth.isLoading$ | async; else loaded">
    <app-loading></app-loading>
</div>

<ng-template #loaded>

    <!-- place holder -->
</ng-template>
<form [formGroup]="jobForm" (ngSubmit)="onSubmit()">

    <mat-card class="" *ngIf="isPositionLoading">

        <div class="position-loader">
            <app-loading size="medium"></app-loading>
        </div>
    </mat-card>
    <mat-card class="">
        <div *ngIf="isSaving" class="overlay">
            <app-loading class="position-loader"></app-loading>
        </div>
        <div class="job-postings-mat-card">
            <div class="job-postings-container">
                <!-- Header with Save as Draft and Publish buttons -->
                <div class="header-buttons sticky">
                    <button mat-raised-button (click)="saveAsDraft()" [disabled]="isSaving">Save as Draft</button>
                    <button mat-raised-button color="primary" (click)="publish()" [disabled]="isSaving">Publish</button>
                </div>

                <div class="clear"></div>

                <!-- Job List -->
                <!-- job-form.component.html -->
                <div class="job-list" *ngIf="jobs && jobs.length > 0" [@fadeIn]="fadeIn">
                    <p>Click on a Job Title in the list bellow to edit a job posting.</p>
                    <table>
                        <thead>
                            <tr>
                                <th>Job Title</th>
                                <th>Position Type</th>
                                <th># of Openings</th>
                                <th>Season</th>
                                <th>Visibility</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let job of jobs" (click)="editJob(job)" class="jobs-row">
                                <td>{{ job.jobTitle }}</td>
                                <td>{{ job.jobTypes.join(', ') }}</td>
                                <td>{{ job.openings }}</td>
                                <td>{{ job.season }}</td>
                                <td>{{ getVisibilityStatus(job) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <mat-card class="section-card"
                *ngIf="positionsInitialData && positionsInitialData.associatedEmployers.length > 1">
                <mat-card-content>

                    <p *ngIf="positionsInitialData && positionsInitialData.associatedEmployers.length > 0"
                        [@fadeIn]="fadeIn" style="margin-bottom: 10px;">
                        As you are member of groups you can edit jobs of employers other than your primary.
                    </p>

                    <div class="position-select"
                        *ngIf="positionsInitialData && positionsInitialData.associatedEmployers.length > 1"
                        [@fadeIn]="fadeIn">
                        <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px" fxFlex="100">
                            <mat-form-field appearance="outline">
                                <mat-label>Employer</mat-label>
                                <mat-select [(value)]="selectedEmployer">
                                    <mat-option *ngFor="let employer of positionsInitialData.associatedEmployers"
                                        [value]="employer">
                                        {{ employer.dba }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- Select button -->
                            <button mat-raised-button (click)="setActiveEmployer()"
                                style="height: 56px; min-width: 100px;">
                                Select
                            </button>
                        </div>
                    </div>


                </mat-card-content>
            </mat-card>


            <!-- job-form.component.html -->
            <mat-card class="section-card">
                <mat-card-content>
                    <!-- First Row: Currently editing notice -->
                    <div *ngIf="currentJobTitle" style="margin-bottom: 10px;">
                        <strong>Currently editing:</strong> {{ currentJobTitle }},
                        <strong>Published:</strong> {{ isPublished ? 'Yes' : 'No' }}
                        &nbsp;
                        <button mat-raised-button (click)=" reloadPageWithoutParams()"
                            style="min-width: 100px;height: 36px; ">
                            New
                        </button>
                        &nbsp;
                        <button mat-raised-button (click)="   deleteJob()" style="min-width: 100px;height: 36px; "
                            color="warn">
                            Delete
                        </button>
                    </div>

                    <p *ngIf="jobs && jobs.length > 0" [@fadeIn]="fadeIn" style="margin-bottom: 10px;">
                        If you would like to create a position based on one you've previously made, select from
                        the dropdown to import information.
                    </p>

                    <div class="position-select" *ngIf="jobs && jobs.length > 0" [@fadeIn]="fadeIn">
                        <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-sm="0px" fxFlex="100">
                            <mat-form-field appearance="outline">
                                <mat-label>Position</mat-label>
                                <mat-select [(value)]="selectedJob">
                                    <mat-option *ngFor="let job of jobs" [value]="job">
                                        {{ job.jobTitle }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- Select button -->
                            <button mat-raised-button (click)="importPosition()"
                                style="height: 56px; min-width: 100px;">
                                Select
                            </button>
                        </div>

                    </div>
                    <!-- Restore button, shown below the Select button and spanning 100% width -->
                    <div *ngIf="hasSavedData" style="margin-top: 10px;">
                        <button fxFlex="70" mat-raised-button color="accent" (click)="restoreSavedData()"
                            style="width: 100%; height: 56px; margin-right: 10px;">
                            Restore unsaved data
                        </button>
                        <button fxFlex="30" mat-raised-button (click)="clearSavedData()"
                            style="width: 100%; height: 56px;">
                            Clear
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>



            <mat-card class="section-card">
                <mat-card-content>
                    <!-- Post Date and Removal Date Section -->
                    <div class="date-section">
                        <p>How long would you like this job listing to be displayed? (You can always remove it
                            manually if you fill the positions sooner).</p>
                        <div fxLayout="row" fxLayoutGap="10px" fxLayout.lt-sm="column">
                            <mat-form-field appearance="outline">
                                <mat-label>Post Date</mat-label>
                                <input matInput [matDatepicker]="postDatePicker" formControlName="postDate"
                                    placeholder="MM/DD/YYYY" [min]="today">
                                <mat-datepicker-toggle matSuffix [for]="postDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #postDatePicker></mat-datepicker>
                                <mat-error *ngIf="jobForm.get('postDate').hasError('required')">Post Date is
                                    required.</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Removal Date</mat-label>
                                <input matInput [matDatepicker]="removalDatePicker" formControlName="removalDate"
                                    placeholder="MM/DD/YYYY" [min]="today">
                                <mat-datepicker-toggle matSuffix [for]="removalDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #removalDatePicker></mat-datepicker>
                                <mat-error *ngIf="jobForm.get('removalDate').hasError('required')">Removal Date is
                                    required.</mat-error>
                                <mat-error
                                    *ngIf="jobForm.get('removalDate').hasError('dateRange') && jobForm.get('postDate').touched && jobForm.get('removalDate').touched">
                                    Removal Date must be after Post Date.
                                </mat-error>

                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="section-card">
                <mat-card-content>
                    <!-- Season, Start/End Dates, and Job Type Section -->
                    <div class="season-section">
                        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Job Title</mat-label>
                                <input matInput formControlName="jobTitle" placeholder="Enter job title" maxlength="50">
                                <mat-error *ngIf="jobForm.get('jobTitle').hasError('required')">Job Title is
                                    required.</mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
                            <!-- Job Title -->
                            <!-- Left Column: Season, Start Date, End Date -->
                            <div fxFlex="50%" fxLayout="column" *ngIf="positionsInitialData" fxLayoutGap="10px">
                                <mat-form-field appearance="outline" *ngIf="seasons" fxFlex="100" [@fadeIn]="fadeIn">
                                    <mat-label>Season</mat-label>
                                    <mat-select formControlName="season" required>
                                        <mat-option *ngFor="let season of seasons" [value]="season">
                                            {{ season }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="jobForm.get('season').hasError('required')">
                                        Season is required.
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" *ngIf="positionsInitialData">
                                    <mat-label>Position Category</mat-label>
                                    <mat-select formControlName="positionCategoryId" required>
                                        <mat-option *ngFor="let positionCat of positionsInitialData.positionCategories"
                                            [value]="positionCat.positionCategoryId">
                                            {{ positionCat.positionCategoryDescription }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="jobForm.get('positionCategoryId').hasError('required')">
                                        Position Category is required.
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label>Start Date</mat-label>
                                    <input matInput [matDatepicker]="startDatePicker" formControlName="startDate"
                                        placeholder="MM/DD/YYYY">
                                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                    <mat-error *ngIf="jobForm.get('startDate').hasError('required')">
                                        Start Date is required.
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" fxFlex="100">
                                    <mat-label>End Date</mat-label>
                                    <input matInput [matDatepicker]="endDatePicker" formControlName="endDate"
                                        placeholder="MM/DD/YYYY" [min]="today">
                                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #endDatePicker></mat-datepicker>
                                    <mat-error *ngIf="jobForm.get('endDate').hasError('required')">
                                        End Date is required.
                                    </mat-error>
                                    <mat-error
                                        *ngIf="jobForm.get('endDate').hasError('dateRange') && jobForm.get('startDate').touched && jobForm.get('endDate').touched">
                                        >
                                        End Date must be after Start Date.
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <!-- Right Column: Job Type Checkboxes -->
                            <div fxFlex="50%" fxLayout="column" fxLayoutGap="10px">
                                <p>Please select releated job type</p>
                                <div fxLayout="row wrap" fxLayoutGap="10px" *ngIf="jobTypes" [@fadeIn]="fadeIn">
                                    <div *ngFor="let jobTypeCtrl of jobTypes.controls; let i = index"
                                        fxFlex="calc(33.333% - 10px)">
                                        <mat-checkbox [formControl]="jobTypeCtrl">
                                            {{ jobTypeList[i].name }}
                                        </mat-checkbox>
                                    </div>
                                </div>
                                <mat-error *ngIf="!jobTypes.valid && jobTypes.touched">
                                    Please select at least one Job Type.
                                </mat-error>
                            </div>


                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="section-card">
                <mat-card-content>
                    <div fxLayout="row" fxLayoutGap="20px">
                        <h3>Eligible Worker Types*</h3>
                        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                            <mat-checkbox formControlName="american">American</mat-checkbox>
                            <mat-checkbox formControlName="inCountryH2B">In-Country H2-B</mat-checkbox>
                            <mat-checkbox formControlName="outOfCountryH2B">Out of Country H2-B</mat-checkbox>
                            <mat-checkbox formControlName="j1">J-1</mat-checkbox>
                        </div>
                    </div>
                    <mat-error
                        *ngIf="jobForm.hasError('atLeastOneWorkerType') && (jobForm.get('american')?.touched || jobForm.get('inCountryH2B')?.touched || jobForm.get('outOfCountryH2B')?.touched || jobForm.get('j1')?.touched)"
                        class="error">
                        At least one worker type must be selected.
                    </mat-error>
                </mat-card-content>
            </mat-card>

            <mat-card class="section-card">
                <mat-card-content>
                    <h3>About this job</h3>

                    <!-- Job Details Section -->
                    <div class="job-details-section" fxLayout="column" fxLayoutGap="20px">

                        <div class="custom-form-field">
                            <div class="quill-wrapper">
                                <quill-editor [sanitize]="true" formControlName="jobDescription"
                                    [modules]="quillModules" placeholder="Enter job description"
                                    [styles]="{ height: '200px'}">
                                </quill-editor>
                            </div>
                        </div>

                        <!-- Skills & Competencies -->
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Skills & Competencies</mat-label>
                            <input matInput formControlName="skills" placeholder="Enter skills and competencies">
                        </mat-form-field>

                        <!-- Number of Openings -->
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label># of Openings</mat-label>
                            <input matInput type="number" formControlName="openings"
                                placeholder="Enter number of openings" min="1">
                            <mat-error *ngIf="jobForm.get('openings').hasError('required')">Number of openings is
                                required.</mat-error>
                        </mat-form-field>

                        <!-- Radio Button Options -->
                        <div fxLayout="row" fxLayoutGap="20px" class="radio-group-container">
                            <!-- Left Column -->
                            <div fxFlex="50%" fxLayout="column" fxLayoutGap="20px">
                                <div>
                                    <p>Job requires manual labor?*</p>
                                    <mat-radio-group formControlName="manualLabor">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-error
                                        *ngIf="jobForm.get('manualLabor').hasError('required') && jobForm.get('manualLabor')?.touched ">Required</mat-error>

                                </div>

                                <div>
                                    <p>Supervises others?*</p>
                                    <mat-radio-group formControlName="supervisesOthers">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-error
                                        *ngIf="jobForm.get('supervisesOthers').hasError('required') && jobForm.get('supervisesOthers')?.touched ">Required</mat-error>

                                </div>
                            </div>

                            <!-- Right Column -->
                            <div fxFlex="50%" fxLayout="column" fxLayoutGap="20px">
                                <div>
                                    <p>Training required?*</p>
                                    <mat-radio-group formControlName="trainingRequired">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-error
                                        *ngIf="jobForm.get('trainingRequired').hasError('required') && jobForm.get('trainingRequired')?.touched ">Required</mat-error>

                                </div>

                                <div>
                                    <p>On the job training?*</p>
                                    <mat-radio-group formControlName="onTheJobTraining">
                                        <mat-radio-button [value]="true">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false">No</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-error
                                        *ngIf="jobForm.get('onTheJobTraining').hasError('required') && jobForm.get('onTheJobTraining')?.touched ">Required</mat-error>

                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="section-card">
                <mat-card-content>
                    <!-- Qualifications Section -->
                    <h3>Qualifications</h3>

                    <!-- Text Fields Container -->
                    <div fxLayout="column" fxLayoutGap="20px">
                        <!-- Minimum Education Dropdown -->
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Minimum Education</mat-label>
                            <mat-select formControlName="education" *ngIf="educationOptions">
                                <mat-option *ngFor="let option of educationOptions" [value]="option" [@fadeIn]="fadeIn">
                                    {{ option }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- Minimum Professional Experience -->
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Minimum Professional Experience</mat-label>
                            <input matInput type="number" formControlName="experience"
                                placeholder="Enter experience in months" min="0">
                            <span matSuffix>months &nbsp;</span>
                        </mat-form-field>

                        <!-- Special Certifications -->
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Special Certifications Required</mat-label>
                            <textarea matInput formControlName="certifications"
                                placeholder="Enter any special certifications"></textarea>
                        </mat-form-field>
                    </div>

                    <!-- Required Items (Radio Buttons) -->
                    <h3>Are any of the following required?</h3>
                    <!-- Radio Button Options -->
                    <div fxLayout="row" fxLayoutGap="20px" class="radio-group-container">
                        <!-- Left Column -->
                        <div fxFlex="50%" fxLayout="column" fxLayoutGap="20px">
                            <!-- Radio Group 1 -->
                            <div>
                                <p>Drug screening?*</p>
                                <mat-radio-group formControlName="drugScreening">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <mat-error
                                    *ngIf="jobForm.get('drugScreening').hasError('required') && jobForm.get('drugScreening')?.touched ">Required.</mat-error>

                            </div>

                            <!-- Radio Group 2 -->
                            <div>
                                <p>Background check?*</p>
                                <mat-radio-group formControlName="backgroundCheck">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <mat-error
                                    *ngIf="jobForm.get('backgroundCheck').hasError('required')  && jobForm.get('backgroundCheck')?.touched ">Required.</mat-error>

                            </div>

                            <!-- Radio Group 3 -->
                            <div>
                                <p>Worker must have their own car?*</p>
                                <mat-radio-group formControlName="ownCar">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <mat-error
                                    *ngIf="jobForm.get('ownCar').hasError('required') && jobForm.get('ownCar')?.touched ">Required.</mat-error>

                            </div>
                        </div>

                        <!-- Right Column -->
                        <div fxFlex="50%" fxLayout="column" fxLayoutGap="20px">
                            <!-- Radio Group 4 -->
                            <div>
                                <p>Driver's license?*</p>
                                <mat-radio-group formControlName="driversLicense">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <mat-error
                                    *ngIf="jobForm.get('driversLicense').hasError('required') && jobForm.get('driversLicense')?.touched ">Required.</mat-error>

                            </div>

                            <!-- Radio Group 5 -->
                            <div>
                                <p>COVID test?*</p>
                                <mat-radio-group formControlName="covidTest">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <mat-error
                                    *ngIf="jobForm.get('covidTest').hasError('required') && jobForm.get('covidTest')?.touched ">Required.</mat-error>

                            </div>

                            <!-- Radio Group 6 -->
                            <div>
                                <p>COVID vaccine?*</p>
                                <mat-radio-group formControlName="covidVaccine">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <mat-error
                                    *ngIf="jobForm.get('covidVaccine').hasError('required') && jobForm.get('covidVaccine')?.touched ">Required.</mat-error>

                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="section-card">
                <mat-card-content>
                    <!-- Wages & Compensation Section -->
                    <h3>Wages & Compensation</h3>

                    <div fxLayout="column" fxLayoutGap="20px">
                        <!-- Hours per Week -->
                        <mat-form-field appearance="outline" fxFlex="100%">
                            <mat-label># of hours per Week</mat-label>
                            <input matInput type="number" formControlName="hoursPerWeek" placeholder="Enter hours"
                                min="0">
                        </mat-form-field>

                        <!-- Hourly Wage Range -->
                        <div fxLayout="row" fxLayoutGap="20px">
                            <!-- Min Wage -->
                            <mat-form-field appearance="outline" fxFlex="50%">
                                <mat-label>Min Hourly Wage</mat-label>
                                <input matInput type="number" formControlName="wageMin" placeholder="Min wage" min="0">
                            </mat-form-field>

                            <!-- Max Wage -->
                            <mat-form-field appearance="outline" fxFlex="50%">
                                <mat-label>Max Hourly Wage</mat-label>
                                <input matInput type="number" formControlName="wageMax" placeholder="Max wage" min="0">
                            </mat-form-field>
                        </div>

                        <!-- Eligibility Options for Pay -->
                        <h3>Is this position eligible for:</h3>

                        <!-- Radio Buttons in 4 Columns -->
                        <div fxLayout="row wrap" fxLayoutGap="20px">
                            <!-- Overtime Pay -->
                            <div fxFlex="calc(20% - 15px)">
                                <p>Overtime pay?*</p>
                                <mat-radio-group formControlName="overtimePay">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <mat-error
                                    *ngIf="jobForm.get('overtimePay').hasError('required') && jobForm.get('overtimePay')?.touched ">Required</mat-error>
                            </div>

                            <!-- Piece-work Pay -->
                            <div fxFlex="calc(20% - 15px)">
                                <p>Piece-work pay?*</p>
                                <mat-radio-group formControlName="pieceWorkPay">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <mat-error
                                    *ngIf="jobForm.get('pieceWorkPay').hasError('required') && jobForm.get('pieceWorkPay')?.touched ">Required</mat-error>

                            </div>

                            <!-- Gratuities -->
                            <div fxFlex="calc(20% - 15px)">
                                <p>Gratuities?*</p>
                                <mat-radio-group formControlName="gratuities">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <mat-error
                                    *ngIf="jobForm.get('gratuities').hasError('required') && jobForm.get('gratuities')?.touched ">Required</mat-error>

                            </div>

                            <!-- Bonus -->
                            <div fxFlex="calc(20% - 15px)">
                                <p>Bonus?*</p>
                                <mat-radio-group formControlName="bonus">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                                <mat-error
                                    *ngIf="jobForm.get('bonus').hasError('required') && jobForm.get('bonus')?.touched ">Required</mat-error>

                            </div>
                        </div>

                        <div fxFlex="calc(20% - 15px)">
                            <mat-checkbox formControlName="isOtherCompensation" fxFlex="15" fxFlex="15" fxFlex.md="45"
                                fxFlex.lt-md="45" fxFlex.lt-sm="100">Other</mat-checkbox>
                        </div>

                        <!-- Other Compensation -->
                        <mat-form-field
                            *ngIf="jobForm.get('isOtherCompensation')?.value || (jobForm.get('otherCompensation')?.value ?? '').length > 0"
                            appearance="outline" fxFlex="100%">
                            <mat-label>Other Compensation (describe, if applicable)</mat-label>
                            <textarea matInput formControlName="otherCompensation"
                                placeholder="Describe other compensation"></textarea>
                        </mat-form-field>

                        <!-- Benefits Section -->
                        <h3>Benefits Offered</h3>
                        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
                            <mat-checkbox formControlName="healthBenefits" fxFlex="12" fxFlex.md="45" fxFlex.lt-md="45"
                                fxFlex.lt-sm="100">Health Benefits</mat-checkbox>
                            <mat-checkbox formControlName="dentalBenefits" fxFlex="12" fxFlex.md="45" fxFlex.lt-md="45"
                                fxFlex.lt-sm="100">Dental Benefits</mat-checkbox>
                            <mat-checkbox formControlName="retentionBonus" fxFlex="12" fxFlex.md="45" fxFlex.lt-md="45"
                                fxFlex.lt-sm="100">Retention Bonus</mat-checkbox>
                            <mat-checkbox formControlName="referralBonus" fxFlex="12" fxFlex.md="45" fxFlex.lt-md="45"
                                fxFlex.lt-sm="100">Referral Bonus</mat-checkbox>
                            <mat-checkbox formControlName="offer401k" fxFlex="12" fxFlex.md="45" fxFlex.lt-md="45"
                                fxFlex.lt-sm="100">401(k)</mat-checkbox>
                            <mat-checkbox formControlName="paidTimeOff" fxFlex="12" fxFlex.md="45" fxFlex.lt-md="45"
                                fxFlex.lt-sm="100">Paid Time Off</mat-checkbox>
                            <mat-checkbox formControlName="isOtherBenefits" fxFlex="15" fxFlex="15" fxFlex.md="45"
                                fxFlex.lt-md="45" fxFlex.lt-sm="100">Other</mat-checkbox>

                            <mat-form-field
                                *ngIf="jobForm.get('isOtherBenefits')?.value || (jobForm.get('otherBenefits')?.value ?? '').length > 0"
                                appearance="outline" fxFlex="100">
                                <mat-label>Other Benefits</mat-label>
                                <input matInput formControlName="otherBenefits" placeholder="Enter other Benefits">
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="section-card">
                <mat-card-content>
                    <!-- Housing Section -->
                    <h3>Housing</h3>

                    <div fxLayout="column" fxLayoutGap="20px">
                        <!-- Employer Provides Housing -->
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-slide-toggle formControlName="employerProvidesHousing">
                                Employer provides housing
                            </mat-slide-toggle>
                        </div>

                        <!-- Rest of the Housing Content -->
                        <!-- This section is displayed only if employerProvidesHousing is true -->
                        <div *ngIf="isHousingProvided" fxLayout="column" fxLayoutGap="20px">
                            <!-- Employer Owned -->
                            <div fxLayout="column">
                                <p>Employer owned?</p>
                                <mat-radio-group formControlName="employerOwned">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <!-- Amounts for Housing -->
                            <div fxLayout="column" fxLayoutGap="20px">
                                <!-- Amount employer provides -->
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>Amount <u>employer</u> provides toward housing (weekly)</mat-label>
                                    <input matInput type="number" formControlName="employerHousingAmount"
                                        placeholder="Enter amount" min="0" />
                                </mat-form-field>

                                <!-- Amount worker pays -->
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>Amount <u>worker</u> pays toward housing (weekly)</mat-label>
                                    <input matInput type="number" formControlName="workerHousingAmount"
                                        placeholder="Enter amount" min="0" />
                                </mat-form-field>

                                <!-- Deposit -->
                                <mat-form-field appearance="outline" fxFlex="100%">
                                    <mat-label>Deposit (if any)</mat-label>
                                    <input matInput type="number" formControlName="deposit"
                                        placeholder="Enter deposit amount" min="0" />
                                </mat-form-field>
                            </div>

                            <!-- Utilities Included -->
                            <div fxLayout="column">
                                <p>Utilities included?</p>
                                <mat-radio-group formControlName="utilitiesIncluded">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <!-- Housing Options -->
                            <h3>Housing Options</h3>
                            <p style="font-size: small;">Select all that apply</p>
                            <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
                                <!-- Location Options -->
                                <mat-checkbox formControlName="onProperty" fxLayout="row wrap"
                                    fxLayoutAlign="start start" fxLayoutGap="20px">On Property</mat-checkbox>
                                <mat-checkbox formControlName="offProperty">Off Property</mat-checkbox>

                                <!-- Roommate Options -->
                                <mat-checkbox formControlName="shared" fxLayout="row wrap" fxLayoutAlign="start start"
                                    fxLayoutGap="20px">Shared</mat-checkbox>
                                <mat-checkbox formControlName="individual" fxLayout="row wrap"
                                    fxLayoutAlign="start start" fxLayoutGap="20px">Individual</mat-checkbox>

                                <!-- Housing Type Options -->
                                <mat-checkbox formControlName="house" fxLayout="row wrap" fxLayoutAlign="start start"
                                    fxLayoutGap="20px">House</mat-checkbox>
                                <mat-checkbox formControlName="apartment" fxLayout="row wrap"
                                    fxLayoutAlign="start start" fxLayoutGap="20px">Apartment</mat-checkbox>
                                <mat-checkbox formControlName="dormitory" fxLayout="row wrap"
                                    fxLayoutAlign="start start" fxLayoutGap="20px">Dormitory</mat-checkbox>

                                <mat-checkbox formControlName="isOtherHousing" fxLayout="row wrap"
                                    fxLayoutAlign="start start" fxLayoutGap="20px">Other</mat-checkbox>

                                <!-- Other Housing Option -->
                                <mat-form-field
                                    *ngIf="jobForm.get('isOtherHousing')?.value || (jobForm.get('otherHousing')?.value ?? '').length > 0"
                                    appearance="outline" fxFlex="100">
                                    <mat-label>Other (please describe)</mat-label>
                                    <input matInput formControlName="otherHousing"
                                        placeholder="Enter other housing options" />
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxLayoutGap="10px">



                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                    <!-- Left Column: Housing Photos and File Types -->
                                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px" fxFlex="15%">
                                        <div>Housing Photos</div>
                                        <div>jpg,jpeg,png,gif</div>
                                        <div>max 1MB</div>
                                    </div>

                                    <!-- Middle Column: Images -->
                                    <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="5px"
                                        fxFlex="85%" style="overflow-x: auto;overflow-y: hidden; height: 250px;">
                                        <div fxLayoutWrap *ngFor="let imgGroup of housingImages.controls; let i = index">
                                            <mat-card style="width: 150px; height: 210px;">
                                              <mat-card-header style="padding:0;">
                                                <button *ngIf="!imgGroup.value.deleted" mat-icon-button color="warn" (click)="deleteImage(i)" aria-label="Delete Image">
                                                  <mat-icon>delete</mat-icon>
                                                </button>
                                                <button *ngIf="imgGroup.value.deleted" mat-icon-button color="primary" (click)="deleteImage(i)" aria-label="Restore Image" title="Restore">
                                                  <mat-icon>restore_from_trash</mat-icon>
                                                </button>
                                              </mat-card-header>
                                              
                                              <img [src]="imgGroup.value.preview" alt="preview" style="width:100%; height:auto; max-height: 100px;">
                                          
                                              <mat-card-content>
                                                <div *ngIf="imgGroup.value.isUploading && !imgGroup.value.isUploaded && !imgGroup.value.deleted">
                                                  <mat-progress-bar mode="determinate" [value]="imgGroup.value.uploadProgress"></mat-progress-bar>
                                                  <p>{{ imgGroup.value.uploadProgress }}%</p>
                                                </div>
                                                <div *ngIf="imgGroup.value.isUploaded && !imgGroup.value.deleted" fxLayoutAlign="center end">
                                                  <p>Uploaded</p>
                                                </div>
                                                <div *ngIf="imgGroup.value.isError && !imgGroup.value.isUploaded && !imgGroup.value.deleted" fxLayoutAlign="center end">
                                                  <p style="color:red;">Upload failed</p>
                                                  <button mat-button (click)="uploadSingleImage(housingImages.at(i))">Retry</button>
                                                </div>
                                                <div *ngIf="imgGroup.value.deleted" fxLayoutAlign="center end">
                                                  <p style="color:gray;">Marked as Deleted</p>
                                                </div>
                                                </mat-card-content>
                                            </mat-card>
                                        </div>

                                        <button (click)="fileInput.click()" [disabled]="undeletedCount >= maxFiles"
                                            mat-button color="accent" class="icon-text-button">
                                            <mat-icon>add_circle</mat-icon>
                                            Add Photos
                                          </button>
                                          
                                          <input #fileInput type="file" (change)="onFileSelected($event)" 
                                            [disabled]="undeletedCount >= maxFiles" accept=".jpg,.jpeg,.png,.gif" style="display: none;" />
                                          
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="section-card">
                <mat-card-content>
                    <!-- Transportation Section -->
                    <h3>Transportation</h3>

                    <div fxLayout="column" fxLayoutGap="20px">
                        <!-- Employer Provides Daily Transportation -->
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-slide-toggle formControlName="employerProvidesTransportation">
                                Employer provides daily transportation
                            </mat-slide-toggle>
                        </div>

                        <!-- Rest of the Transportation Content -->
                        <!-- This section is displayed only if employerProvidesTransportation is true -->
                        <div *ngIf="isTransportationProvided" fxLayout="column" fxLayoutGap="20px">
                            <!-- Transportation Type -->
                            <h3>Transportation Type:</h3>
                            <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
                                <mat-checkbox formControlName="publicTransportation" fxFlex="15" fxFlex.md="45"
                                    fxFlex.lt-md="45" fxFlex.lt-sm="100">Public
                                    transportation</mat-checkbox>
                                <mat-checkbox formControlName="shuttle" fxFlex="15" fxFlex.md="45" fxFlex.lt-md="45"
                                    fxFlex.lt-sm="100">Shuttle</mat-checkbox>
                                <mat-checkbox formControlName="car" fxFlex="15" fxFlex.md="45" fxFlex.lt-md="45"
                                    fxFlex.lt-sm="100">Car</mat-checkbox>
                                <mat-checkbox formControlName="bicycle" fxFlex="15" fxFlex.md="45" fxFlex.lt-md="45"
                                    fxFlex.lt-sm="100">Bicycle</mat-checkbox>
                                <mat-checkbox formControlName="carpool" fxFlex="15" fxFlex.md="45" fxFlex.lt-md="45"
                                    fxFlex.lt-sm="100">Carpool</mat-checkbox>
                                <mat-checkbox formControlName="isOtherTransport" fxFlex="15" fxFlex="15" fxFlex.md="45"
                                    fxFlex.lt-md="45" fxFlex.lt-sm="100">Other</mat-checkbox>
                                <mat-form-field
                                    *ngIf="jobForm.get('isOtherTransport')?.value || (jobForm.get('otherTransport')?.value ?? '').length > 0"
                                    appearance="outline" fxFlex="100">
                                    <mat-label>Other (please describe)</mat-label>
                                    <input matInput formControlName="otherTransport"
                                        placeholder="Enter other transportation options" />
                                </mat-form-field>
                            </div>
                            <!-- Cost to Worker (Weekly) -->
                            <mat-form-field appearance="outline" fxFlex="100%">
                                <mat-label>Cost to worker (weekly)</mat-label>
                                <input matInput type="number" formControlName="transportCost"
                                    placeholder="Enter weekly cost" min="0" />
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="section-card">
                <mat-card-content>
                    <!-- Application Questions Section -->
                    <h3>Application Questions</h3>

                    <!-- Display the list of questions -->
                    <div *ngFor="let question of applicationQuestions.controls; let i = index" [formGroup]="question">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                            <!-- Check if the current question is being edited -->
                            <ng-container *ngIf="editIndex === i; else displayQuestion">
                                <!-- Editing Mode -->
                                <mat-form-field fxFlex appearance="outline">
                                    <input matInput formControlName="text" placeholder="Edit question">
                                    <mat-error *ngIf="question.get('text').invalid">Question is required.</mat-error>
                                </mat-form-field>
                                <mat-checkbox formControlName="required">Required</mat-checkbox>
                                <div>
                                    <button mat-icon-button color="primary" (click)="saveEditedQuestion(i)"
                                        [disabled]="question.invalid">
                                        <mat-icon>check</mat-icon>
                                    </button>
                                    <button mat-icon-button color="warn" (click)="cancelEdit(i)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                            <!-- Display Mode -->
                            <ng-template #displayQuestion>
                                <p fxFlex>{{ question.value.text }}</p>
                                <mat-checkbox [checked]="question.value.required" disabled>Required</mat-checkbox>
                                <div>
                                    <button mat-icon-button color="primary" (click)="editQuestion(i)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-icon-button color="warn" (click)="removeQuestion(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </ng-template>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                    <!-- Add new question -->
                    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="10px" style="margin-top: 20px;"
                        [formGroup]="newQuestionGroup">
                        <mat-form-field appearance="outline" fxFlex>
                            <mat-label>Add a Question</mat-label>
                            <input matInput formControlName="text" placeholder="Type your question here">
                            <mat-error *ngIf="newQuestionGroup.get('text').invalid">Question is required.</mat-error>
                        </mat-form-field>
                        <mat-checkbox formControlName="required">Required</mat-checkbox>
                        <button mat-raised-button color="primary" (click)="addQuestion()"
                            [disabled]="newQuestionGroup.value.text.trim().length < 1"
                            style="height: 56px; min-width: 100px;">
                            Add
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="section-card">
                <mat-card-content>
                    <!-- Application Questions Section -->
                    <div
                        *ngIf="positionsInitialData?.employerContacts?.length && jobForm.get('contacts') as contactsArray">
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <h3>Contacts</h3>
                            <button mat-raised-button color="primary" (click)="toggleSelectAll($event)"
                                [disableRipple]="true">
                                {{ !selectAll ? 'Deselect All' : 'Select All' }}
                            </button>
                        </div>
                        <div fxLayout="row wrap" fxLayoutGap="10px" *ngIf="positionsInitialData" [@fadeIn]="fadeIn"
                            formArrayName="contacts">
                            <div *ngFor="let employerContact of positionsInitialData.employerContacts; let i = index"
                                fxFlex="calc(33.333% - 10px)">
                                <mat-checkbox [formControlName]="i">
                                    {{ employerContact.name }}
                                </mat-checkbox>
                            </div>
                        </div>
                        <mat-error *ngIf="!contacts.valid && contacts.touched">
                            Please select at least one contact.
                        </mat-error>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-card>
</form> 